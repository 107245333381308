import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { FcBriefcase } from "react-icons/fc";

const Kategori = () => {
  return (
    <div>
      <Header titleHead="Kategori" icon={<FcBriefcase />} />
      <Outlet />
    </div>
  );
};

export default Kategori;
