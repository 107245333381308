import { Outlet } from "react-router-dom";
import Header from "../../components/Header";

import { FcBriefcase } from "react-icons/fc";

const Laporan = () => {
  return (
    <div>
      <Header titleHead="Laporan" icon={<FcBriefcase />} />
      <Outlet />
    </div>
  );
};

export default Laporan;
