import React from "react";
import { NavLink } from "react-router-dom";
import { HiOutlineInbox, HiFolderOpen, HiUserGroup } from "react-icons/hi";
import bappenda from "../../assets/images/bappenda1.png";

const Sidebar = () => {
  const menu = [
    { name: "Home", icon: <HiOutlineInbox size={22} />, route: "/" },
    { name: "User", icon: <HiUserGroup size={22} />, route: "/user" },
    {
      name: "Lap. Kegiatan",
      icon: <HiFolderOpen size={22} />,
      route: "/laporan",
    },
  ];

  return (
    <div className="fixed z-20 h-screen border-r border-gray-200 w-64 px-6 bg-gradient-to-b from-black to-blue-600">
      <img src={bappenda} className="w-[180px] mt-3" alt="logo" />
      <div className="text-lg font-poppins-semibold py-2 mb-6 text-gray-100">
        eCATKER
      </div>
      <div>
        <div className="mb-4 text-gray-100 text-md font-poppins-semibold">
          MAIN MENU
        </div>
        <ul className="space-y-4">
          {menu.map((val, index) => {
            return (
              <li
                key={index}
                className="flex flex-row items-center text-gray-200 font-poppins-medium text-md"
              >
                <div className="mr-4 rounded-md">{val.icon}</div>
                <NavLink
                  to={val.route}
                  className={({ isActive }) =>
                    isActive
                      ? "text-orange-300 font-poppins-semibold"
                      : "hover:text-yellow-400"
                  }
                >
                  {val.name}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
