import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import Breadcrumbs from "../Breadcrumbs";
import { FcPrivacy } from "react-icons/fc";

import { SwitchHari, SwitchBulan } from "../../function/DateFormat";

const Header = (props) => {
  const navigate = useNavigate();

  let date = new Date();
  let tahun = date.getFullYear();
  let bulan = date.getMonth();
  let tanggal = date.getDate();
  let hari = date.getDay();
  let jam = date.getHours();
  let menit = date.getMinutes();
  let detik = date.getSeconds();

  const [tglShow, setTglShow] = useState("");

  useEffect(() => {
    setTglShow(
      `${SwitchHari(hari)}, ${tanggal} ${SwitchBulan(
        bulan
      )} ${tahun} ${jam}:${menit}:${detik}`
    );
  }, []);

  const Logout = () => {
    localStorage.removeItem("tokenEcatker");
    navigate("/login");
  };

  return (
    <div className="w-full fixed top-0 z-10 left-0 border-b mb-4 bg-white pt-4">
      <div className="ml-64">
        <div className="flex flex-row justify-between items-center px-2">
          <div>
            <div className="flex flex-row items-center">
              <div className="mr-3">
                <IconContext.Provider value={{ size: 20 }}>
                  {props.icon}
                </IconContext.Provider>
              </div>
              <h1 className="font-poppins-medium text-sm">{props.titleHead}</h1>
            </div>
          </div>
          <div>
            <button
              type="button"
              onClick={() => Logout()}
              className="font-poppins-medium text-sm text-gray-500 bg-white hover:bg-gray-50 py-1 px-2 shadow-sm border border-gray-200 rounded-lg inline-flex items-center"
            >
              <span className="mr-2">
                <FcPrivacy />
              </span>
              <span>Log Out</span>
            </button>
          </div>
        </div>
        <div className="flex flex-row justify-between items-center px-2 border-t mt-2">
          <div className="flex-1">
            <Breadcrumbs />
          </div>
          <div className="flex-1">
            <div className="text-right font-poppins-regular text-xs">
              {tglShow}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
