import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import jwtDecode from "jwt-decode";

import { FcHome } from "react-icons/fc";
import { API_URL } from "../../config";

const Home = () => {
  const navigate = useNavigate();
  const [namaUser, setNamaUser] = useState("");
  const [totalUser, setTotalUser] = useState(0);
  const [approveY, setApproveY] = useState(0);
  const [approveN, setApproveN] = useState(0);

  useEffect(() => {
    checkToken();

    loadTotalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenEcatker");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      } else {
        setNamaUser(decoded.namaUser);
      }
    } else {
      navigate("/login");
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenEcatker");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const loadTotalData = async () => {
    try {
      const response = await axiosJWT.get(`${API_URL}/total-data`);

      setTotalUser(response.data.user);
      setApproveY(response.data.approveY);
      setApproveN(response.data.approveN);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <Header titleHead="Home" icon={<FcHome />} />
      <div className="p-4 ">
        <p className="font-poppins-regular text-sm">
          Selamat Datang, <strong>{namaUser}</strong>!
        </p>

        <div className="grid grid-cols-3 gap-4">
          <div className="p-4 shadow-lg rounded-lg">
            <p className="font-poppins-regular text-sm text-gray-400">
              Total User
            </p>
            <p className="font-poppins-semibold text-2xl">{totalUser}</p>
          </div>
          <div className="p-4 shadow-lg rounded-lg">
            <p className="font-poppins-regular text-sm text-gray-400">
              Total Kegiatan Sudah Disetujui
            </p>
            <p className="font-poppins-semibold text-2xl">{approveY}</p>
          </div>
          <div className="p-4 shadow-lg rounded-lg">
            <p className="font-poppins-regular text-sm text-gray-400">
              Total Kegiatan Belum Disetujui
            </p>
            <p className="font-poppins-semibold text-2xl">{approveN}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
