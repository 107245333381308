import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Paths } from "./routes/paths";

const router = createBrowserRouter(Paths);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
