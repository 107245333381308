import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { useNavigate, useParams } from "react-router-dom";
import { HiSave, HiArrowNarrowLeft } from "react-icons/hi";

import InputText from "../../components/Form2/InputText";
import InputTgl from "../../components/Form2/InputTgl";

const FormEdit = () => {
  const { id } = useParams();

  const [namaUser, setNamaUser] = useState("");
  const [nip, setNip] = useState("");
  const [tmpLahir, setTmpLahir] = useState("");
  const [tglLahir, setTglLahir] = useState("");
  const [bidang, setBidang] = useState("");
  const [subBidang, setSubBidang] = useState("");
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    // get refresh token
    checkToken();

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkToken = () => {
    const token = localStorage.getItem("tokenEcatker");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenEcatker");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const loadData = async () => {
    try {
      const user = await axiosJWT.get(`${API_URL}/user/${id}`);

      setNip(user.data.nip);
      setNamaUser(user.data.namaUser);
      setTmpLahir(user.data.tmpLahir);
      setTglLahir(user.data.tglLahir);
      setBidang(user.data.bidang);
      setSubBidang(user.data.subBidang);
      setUsername(user.data.username);

      console.log(user.data.tglLahir);
    } catch (error) {
      console.log(error.message);
    }
  };

  const simpan = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosJWT.put(`${API_URL}/user/${id}`, {
        namaUser: namaUser,
        nip: nip,
        tmpLahir: tmpLahir,
        tglLahir: new Date(tglLahir),
        bidang: bidang,
        subBidang: subBidang,
        username: username,
      });

      Swal.fire({
        icon: "success",
        title: "Sukses!",
        text: response.data.message,
        confirmButtonText: "Oke, lihat data!",
        willClose: () => {
          navigate(-1);
        },
      });
    } catch (error) {
      Swal.fire("Error!", error.response.data.message, "error");
    }
  };

  return (
    <div className="mx-10">
      <div>
        <h2 className="font-poppins-semibold text-lg text-indigo-700">
          Edit User
        </h2>
        <p className="text-sm font-poppins-light text-gray-400">
          Perubahan data user, isi informasi data dibawah ini.
        </p>
      </div>
      <form onSubmit={simpan}>
        <div className="mt-5 grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6">
          <div className="sm:col-span-2">
            <InputText
              label="NIP"
              name="nip"
              val={nip}
              set={setNip}
              placeholder="ex. 111"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-4">
            <InputText
              label="Nama User"
              name="namaUser"
              val={namaUser}
              set={setNamaUser}
              placeholder="Masukkan nama user"
            />
          </div>

          <div className="sm:col-start-1 sm:col-span-2">
            <InputText
              label="Tempat Lahir"
              name="tmpLahir"
              val={tmpLahir}
              set={setTmpLahir}
              placeholder="Masukkan tempat lahir"
            />
          </div>
          <div className="sm:col-span-2">
            <InputTgl
              label="Tgl. Lahir"
              name="tglLahir"
              val={tglLahir}
              set={setTglLahir}
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              format tanggal 99/99/9999
            </p>
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <InputText
              label="Bidang"
              name="bidang"
              val={bidang}
              set={setBidang}
              placeholder="Masukkan nama bidang"
            />
          </div>

          <div className="sm:col-span-2">
            <InputText
              label="Sub. Bidang"
              name="subBidang"
              val={subBidang}
              set={setSubBidang}
              placeholder="Masukkan nama sub. bidang"
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-2">
            <InputText
              label="Username"
              name="username"
              val={username}
              set={setUsername}
              placeholder="Masukkan username"
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              username untuk login ke aplikasi playstore
            </p>
          </div>
        </div>
        <div className="mt-10 flex items-center gap-x-2">
          <button
            type="button"
            className="text-gray-700 bg-[#fbbf24] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            onClick={() => navigate(-1)}
          >
            <span className="mr-2">
              <HiArrowNarrowLeft />
            </span>
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
          >
            <span className="mr-2">
              <HiSave />
            </span>
            <span>Simpan Data</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormEdit;
