import Root from "./root";
import Login from "../pages/Login";

import Home from "../pages/Home";

import Kategori from "../pages/Kategori";
import TableKategori from "../pages/Kategori/Table";
import FormAddKategori from "../pages/Kategori/FormAdd";
import FormEditKategori from "../pages/Kategori/FormEdit";

import User from "../pages/User";
import TableUser from "../pages/User/Table";
import FormAddUser from "../pages/User/FormAdd";
import FormEditUser from "../pages/User/FormEdit";
import FormPassword from "../pages/User/FormPassword";

import Laporan from "../pages/Laporan";
import TableLaporan from "../pages/Laporan/Table";

import ErrorPage from "../pages/ErrorPage";

export const Paths = [
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "kategori",
        element: <Kategori />,
        breadcrumb: "Kategori",
        children: [
          { index: true, element: <TableKategori />, breadcrumb: "Kategori" },
          {
            path: "add",
            element: <FormAddKategori />,
            breadcrumb: "Tambah Kategori",
          },
          {
            path: ":id",
            element: <FormEditKategori />,
            breadcrumb: "Edit Kategori",
          },
        ],
      },
      {
        path: "user",
        element: <User />,
        breadcrumb: "User",
        children: [
          { index: true, element: <TableUser />, breadcrumb: "User" },
          {
            path: "add",
            element: <FormAddUser />,
            breadcrumb: "Tambah User",
          },
          {
            path: ":id",
            element: <FormEditUser />,
            breadcrumb: "Edit User",
          },
          {
            path: "password/:id",
            element: <FormPassword />,
            breadcrumb: "Edit Password",
          },
        ],
      },
      {
        path: "laporan",
        element: <Laporan />,
        breadcrumb: "Lapora",
        children: [
          { index: true, element: <TableLaporan />, breadcrumb: "User" },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
];
