const InputPassword = ({ label, name, set, val }) => {
  return (
    <>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-poppins-light text-gray-400"
      >
        {label}
      </label>
      <input
        type="password"
        name={name}
        id={name}
        placeholder="•••"
        value={val}
        onChange={(e) => set(e.target.value)}
        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5 font-poppins-regular"
        required
      />
    </>
  );
};

export default InputPassword;
