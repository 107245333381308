import React, { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { API_URL } from "../../config/index";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaPrint, FaSearch, FaSort } from "react-icons/fa";
import {
  BsCheck2Circle,
  BsFillPencilFill,
  BsFillTrashFill,
} from "react-icons/bs";

import { formatDate } from "../../function/DateFormat";

import InputDatetime from "../../components/Form2/InputDatetime";
import SelectUser from "../../components/Form2/SelectUser";
import SelectApprove from "../../components/Form2/SelectApprove";

const Table = () => {
  const navigate = useNavigate();

  const [kegiatan, setKegiatan] = useState([]);
  const [page, setPage] = useState(0);
  /* eslint-disable-next-line */
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [field, setField] = useState("namaPerusahaan");
  const [fieldQuery, setFieldQuery] = useState("namaPerusahaan");
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");

  const [users, setUsers] = useState([]);

  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [userId, setUserId] = useState("");
  const [statusApprove, setStatusapprove] = useState("");

  const [date1Query, setDate1Query] = useState("");
  const [date2Query, setDate2Query] = useState("");
  const [userIdQuery, setUserIdQuery] = useState("");
  const [statusApproveQ, setStatusApproveQ] = useState("");

  useEffect(() => {
    // get refresh token
    checkToken();

    findUser();
    getAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldQuery,
    keyword,
    date1Query,
    date2Query,
    userIdQuery,
    statusApproveQ,
    limit,
    page,
  ]);

  const checkToken = () => {
    const token = localStorage.getItem("tokenEcatker");
    if (token) {
      const decoded = jwtDecode(token);

      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("tokenEcatker");
      if (token) {
        const decoded = jwtDecode(token);

        const currentDate = new Date();
        if (decoded.exp * 1000 < currentDate.getTime()) {
          navigate("/login");
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } else {
        navigate("/login");
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const deleteData = async (id) => {
    Swal.fire({
      title: "Konfirmasi Hapus?",
      text: "Yakin ingin menghapus data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, hapus data!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await axiosJWT
          .delete(`${API_URL}/admin/kegiatan/${id}`)
          .then((response) => {
            getAllData();
            Swal.fire("Deleted!", response.data.message, "success");
          })
          .catch((error) => {
            Swal.fire("Deleted!", error.response.data.message, "error");
          });
      }
    });
  };

  const editDeskripsi = async (deskripsi, id) => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Deskripsi",
      inputPlaceholder: "Deskripsi Kegiatan...",
      inputValue: deskripsi,
      inputAttributes: {
        "aria-label": "Type your kegiatan here",
      },
      showCancelButton: true,
    });

    if (text) {
      await axiosJWT
        .put(`${API_URL}/admin/kegiatan/${id}`, {
          deskripsi: text,
        })
        .then((response) => {
          getAllData();
          Swal.fire("Success Update!", response.data.message, "success");
        })
        .catch((error) => {
          Swal.fire("Success Update!", error.response.data.message, "error");
        });
    }
  };

  const editJudul = async (deskripsi, id) => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Judul",
      inputPlaceholder: "Judul Kegiatan...",
      inputValue: deskripsi,
      inputAttributes: {
        "aria-label": "Type your kegiatan here",
      },
      showCancelButton: true,
    });

    if (text) {
      await axiosJWT
        .put(`${API_URL}/admin/kegiatan/${id}`, {
          judul: text,
        })
        .then((response) => {
          getAllData();
          Swal.fire("Success Update!", response.data.message, "success");
        })
        .catch((error) => {
          Swal.fire("Success Update!", error.response.data.message, "error");
        });
    }
  };

  const approveKegiatan = async (id, status) => {
    await axiosJWT
      .put(`${API_URL}/admin/kegiatan/${id}`, {
        statusApprove: status,
      })
      .then(() => {
        getAllData();
        Swal.fire(
          "Success Approve!",
          "Berhasil melakukan approve kegiatan.",
          "success"
        );
      })
      .catch((error) => {
        Swal.fire("Success Approve!", error.data.message, "error");
      });
  };

  const findUser = async () => {
    try {
      const getUser = await axiosJWT.get(`${API_URL}/user-select`);

      setUsers(getUser.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllData = async () => {
    await axiosJWT
      .get(
        `${API_URL}/admin/kegiatan?date1=${date1Query}&date2=${date2Query}&userId=${userIdQuery}&status=${statusApproveQ}&page=${page}&limit=${limit}`
      )
      .then((response) => {
        setKegiatan(response.data.docs);
        setPage(response.data.page);
        setPages(response.data.totalPages);
        setRows(response.data.totalDocs);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const downloadPdf = async (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Proses Download!",
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: async () => {
        Swal.showLoading();

        try {
          await axiosJWT
            .get(
              `${API_URL}/admin/kegiatan-pdf?date1=${date1Query}&date2=${date2Query}&userId=${userId}&status=${statusApproveQ}`
            )
            .then(async (response) => {
              const fileName = response.data.fileName;
              const message = response.data.message;

              fetch(`${API_URL}/pdf/output/${fileName}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/pdf",
                },
              })
                .then((response) => response.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(new Blob([blob]));
                  const link = document.createElement("a");
                  link.href = url;
                  link.setAttribute("download", fileName); // nama file dan extension sesuaikan dengan file yang di download
                  document.body.appendChild(link);
                  link.click();

                  Swal.hideLoading();
                  Swal.fire("Download Success!", message, "success");
                });
            })
            .catch((error) => {
              console.log(error.message);
            });
        } catch (error) {
          console.log(error.message);
        }
      },
    });
  };

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 9) {
      setMsg(
        "Jika tidak menemukan data yang Anda cari, silahkan cari data dengan kata kunci spesifik!"
      );
    } else {
      setMsg("");
    }
  };

  const filterData = (e) => {
    e.preventDefault();

    setUserIdQuery(userId);
    setDate1Query(date1);
    setDate2Query(date2);
    setStatusApproveQ(statusApprove);

    console.log(date1, date2);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
    setFieldQuery(field);
  };

  const resetPage = (e) => {
    e.preventDefault();
    setPage(0);
    setQuery("");
    setField("namaKategori");
    setKeyword("");
    setFieldQuery("namaKategori");
    setDate1("");
    setDate2("");
    setDate1Query("");
    setDate2Query("");
    setUserId("");
    setUserIdQuery("");
    setStatusapprove("N");
    setStatusApproveQ("N");
    setLimit(10);
  };

  return (
    <>
      <form onSubmit={filterData}>
        <div className="grid grid-cols-1 gap-x-4 gap-y-3 sm:grid-cols-6 ml-4">
          <div className="sm:col-span-2">
            <InputDatetime
              label="Mulai"
              name="date1"
              val={date1}
              set={setDate1}
            />
          </div>
          <div className="sm:col-span-2">
            <InputDatetime
              label="Akhir"
              name="date2"
              val={date2}
              set={setDate2}
            />
          </div>
          <div className="sm:col-start-1 sm:col-span-3">
            <SelectUser
              label="Pegawai"
              name="userId"
              val={userId}
              set={setUserId}
              item={users}
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Pilih pegawai untuk di cari kegiatan
            </p>
          </div>
          <div className="sm:col-span-1">
            <SelectApprove
              label="Status Approve"
              name="statusApprove"
              val={statusApprove}
              set={setStatusapprove}
              item={[
                {
                  status: "N",
                  namaApprove: "Belum Disetujui",
                },
                {
                  status: "Y",
                  namaApprove: "Sudah Disetujui",
                },
              ]}
            />
            <p className="mt-1 text-xs font-poppins-regular leading-6 text-gray-300">
              Pilih status approve {statusApproveQ}
            </p>
          </div>
        </div>
        <div className="max-w-screen-xl flex flex-row items-center mb-4">
          <div className="ml-4">
            <button
              type="submit"
              className="text-white bg-[#3b5998] hover:bg-[#3b5998]/90 active::bg-[#3b5998]/50  focus:outline-none focus:ring-[#3b5998]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
            >
              <span className="mr-2">
                <FaSearch />
              </span>
              <span>Cari Laporan</span>
            </button>
          </div>
          <div className="flex-1 mr-4">
            <div className="flex justify-end items-center space-x-2">
              <button
                type="button"
                onClick={downloadPdf}
                className="text-white bg-[#e11d48] hover:bg-[#e11d48]/90 active::bg-[#e11d48]/50  focus:outline-none focus:ring-[#e11d48]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
              >
                <span className="mr-2">
                  <FaPrint />
                </span>
                <span>Download PDF</span>
              </button>
              <button
                type="button"
                className="text-gray-700 bg-[#ffffff] hover:bg-[#fbbf24]/90 active::bg-[#fbbf24]/50  focus:outline-none focus:ring-[#b91c1c]/50 font-poppins-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center shadow-md"
                onClick={resetPage}
              >
                <span className="mr-2">
                  <HiOutlineRefresh />
                </span>
                <span>Reload</span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="relative overflow-x-auto mb-3">
        <table className="w-full font-poppins-regular text-xs text-left text-gray-500 border-b">
          <thead className="text-xs border-b border-t text-gray-600 bg-gradient-to-b from-gray-200 to-gray-100">
            <tr>
              <th scope="col" className="p-2 border-r">
                #
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Judul
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Nama
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Deskripsi
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Approve
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Created At
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>
              <th scope="col" className="px-2 py-3 border-r">
                <div className="flex items-center">
                  Updated At
                  <Link to="">
                    <FaSort />
                  </Link>
                </div>
              </th>

              <th scope="col" className="px-2 py-3">
                <span>Aksi</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {kegiatan ? (
              <>
                {kegiatan.map((val, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b hover:bg-gray-50"
                    >
                      <td className="p-2 border-r">
                        <div className="flex items-center">
                          <input
                            id="checkbox-all-search"
                            type="checkbox"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                          />
                          <label
                            htmlFor="checkbox-all-search"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                      <td className="px-2 py-2 text-gray-900 border-r">
                        {val.judul}
                        <button
                          type="button"
                          onClick={() => editJudul(val.judul, val._id)}
                          className="font-medium text-gray-500 px-2 py-2 hover:bg-gray-200 hover:rounded-full"
                        >
                          <span>
                            <BsFillPencilFill />
                          </span>
                        </button>
                      </td>
                      <td className="px-2 py-2 border-r">
                        {val.userId.namaUser}
                      </td>
                      <td className="px-2 py-2 border-r">
                        {val.deskripsi}{" "}
                        <button
                          type="button"
                          onClick={() => editDeskripsi(val.deskripsi, val._id)}
                          className="font-medium text-gray-500 px-2 py-2 hover:bg-gray-200 hover:rounded-full"
                        >
                          <span>
                            <BsFillPencilFill />
                          </span>
                        </button>
                      </td>
                      <td className="px-2 py-2 border-r w-[120px]">
                        {val.statusApprove === "Y" ? (
                          <span className="bg-green-100 text-green-800 font-poppins-semibold px-2.5 py-0.5 text-[10px] rounded">
                            Sudah Disetujui
                          </span>
                        ) : (
                          <>
                            <span className="bg-red-100 text-red-800 font-poppins-semibold px-2.5 py-0.5 text-[10px] rounded">
                              Belum Disetujui
                            </span>
                            <button
                              type="button"
                              onClick={() => approveKegiatan(val._id, "Y")}
                              className="font-poppins-regular text-[11px] text-green-600 px-2 py-1 rounded-lg border border-green-400 flex flex-row items-center mt-2 shadow-sm hover:bg-green-200"
                            >
                              <span>
                                <BsCheck2Circle />
                              </span>
                              <span className="ml-1">Setujui</span>
                            </button>
                          </>
                        )}
                      </td>
                      <td className="px-2 py-2 border-r">
                        {formatDate(val.createdAt)}
                      </td>
                      <td className="px-2 py-2 border-r">
                        {formatDate(val.updatedAt)}
                      </td>
                      <td className="px-2 py-2 text-center border-r">
                        <div className="inline-flex flex-row">
                          <button
                            type="button"
                            onClick={() => deleteData(val._id)}
                            className="font-medium text-gray-500 px-2 py-2 hover:bg-gray-200 hover:rounded-full"
                          >
                            <span>
                              <BsFillTrashFill />
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            ) : (
              <tr className="bg-white border-b hover:bg-gray-50">
                <td colSpan="5" className="px-2 py-2 text-center">
                  Data tidak ditemukan
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex flex-row items-center justify-between px-2">
        <div>
          <span className="text-xs font-poppins-regular">
            Total Rows: {rows} Page: {rows ? page : 0} of {pages}
          </span>
        </div>
        <div>
          <select
            id="showData"
            className="bg-gray-100 border border-gray-300 text-gray-900 text-xs font-poppins-regular rounded-lg focus:ring-blue-500 focus:border-blue-500 py-2"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <nav aria-label="Page navigation example">
          <ReactPaginate
            previousLabel={"Prev"}
            nextLabel={"Next"}
            pageCount={Math.min(10, pages)}
            onPageChange={changePage}
            containerClassName={
              "inline-flex items-center -space-x-px text-xs font-poppins-regular"
            }
            pageLinkClassName={
              "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
            }
            previousLinkClassName={
              "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
            }
            nextLinkClassName={
              "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
            }
            activeLinkClassName={
              "z-10 px-3 py-2 leading-tight bg-indigo-200 text-indigo-500 cursor-auto font-bold border border-indigo-300 hover:bg-indigo-200 hover:text-indigo-500"
            }
            disabledLinkClassName={
              "z-10 px-3 py-2 leading-tight text-indigo-500 border border-indigo-300 cursor-auto bg-indigo-200 hover:bg-indigo-200 hover:text-indigo-500"
            }
          />
        </nav>
      </div>
    </>
  );
};

export default Table;
