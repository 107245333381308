import React from "react";
import { Link, useLocation } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Paths } from "../../routes/paths";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(Paths, { disableDefaults: true });
  const location = useLocation();

  return (
    <nav className="flex py-2 text-gray-700 mb-1" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        <li className="inline-flex items-center">
          <Link
            to="/"
            className="inline-flex items-center font-poppins-medium text-xs text-blue-700 hover:text-gray-400"
          >
            <svg
              aria-hidden="true"
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            Home
          </Link>
        </li>
        {breadcrumbs.map(({ match, breadcrumb }, index) => {
          return (
            <li key={index}>
              <div className="flex items-center">
                <svg
                  aria-hidden="true"
                  className="w-4 h-4 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                {match.pathname === location.pathname ? (
                  <span className="ml-1 text-xs font-poppins-medium text-gray-400 md:ml-2">
                    {breadcrumb}
                  </span>
                ) : (
                  <Link
                    to={match.url}
                    className="ml-1 text-xs font-poppins-medium text-blue-700 hover:text-gray-400 md:ml-2"
                  >
                    {breadcrumb}
                  </Link>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
