import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";

const Root = () => {
  return (
    <div className="w-full min-h-screen bg-white flex flex-row">
      <Sidebar />
      <section className="flex-auto bg-white mt-32 mb-10">
        <div className="relative ml-64">
          <Outlet />
        </div>
      </section>
    </div>
  );
};

export default Root;
